import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import { withStyles } from '@material-ui/core/styles'

const Navul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Navli = styled.li``

const Navhref = styled(Link)`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 0.8rem;
  text-decoration: none;
  color: #1d1d1d;
  padding: 0.6em 1em;
  display: block;
  text-transform: uppercase;

  &:hover {
    color: #d3a04e;
  }
`

const BurgerWrapper = styled.div`
  padding-left: 45px;
  button {
    color: #d3a04e;

    svg {
      width: 45px;
      height: 45px;
    }
  }
`

function ListItemLink(props) {
  return <ListItem button component={Link} {...props} />
}

const styles = {
  paper: {
    background: '#fff',
  },
}

class Navigation extends Component {
  componentDidMount = () => {
    this.updatePredicate()
    window.addEventListener('resize', this.updatePredicate)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updatePredicate)
  }

  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth > 1200 })
  }

  state = {
    open: false,
    isDesktop: false,
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        {this.state.isDesktop ? (
          <div>
            <Navul>
              <Navli>
                <Navhref to="/">Home</Navhref>
              </Navli>
              <Navli>
                <Navhref to="/about-us">Über uns</Navhref>
              </Navli>
              <Navli>
                <Navhref to="/werte">Werte</Navhref>
              </Navli>
              <Navli>
                <Navhref to="/honorar">Honorar</Navhref>
              </Navli>
              <Navli>
                <Navhref to="/karriere">Karriere</Navhref>
              </Navli>
              <Navli>
                <Navhref to="/leistungen">Leistungen</Navhref>
              </Navli>
              <Navli>
                <Navhref to="/branchen">Branchen</Navhref>
              </Navli>
              <Navli>
                <Navhref to="/kontakt">Kontakt</Navhref>
              </Navli>
            </Navul>
          </div>
        ) : (
          <div>
            <BurgerWrapper>
              <Button onClick={this.toggleDrawer('open', true)}>
                <MenuIcon />
              </Button>
            </BurgerWrapper>
            <Drawer
              anchor="right"
              open={this.state.open}
              onClose={this.toggleDrawer('open', false)}
              classes={{ paper: classes.paper }}
            >
              <div
                tabIndex={0}
                role="button"
                onClick={this.toggleDrawer('open', false)}
                onKeyDown={this.toggleDrawer('open', false)}
              >
                <Navul>
                  <List>
                    <ListItemLink to="/">
                      <ListItemText primary="Home" />
                    </ListItemLink>
                    <ListItemLink to="/about-us">
                      <ListItemText primary="Über uns" />
                    </ListItemLink>
                    <ListItemLink to="/werte">
                      <ListItemText primary="Werte" />
                    </ListItemLink>
                    <ListItemLink to="/honorar">
                      <ListItemText primary="Honorar" />
                    </ListItemLink>
                    <ListItemLink to="/karriere">
                      <ListItemText primary="Karriere" />
                    </ListItemLink>
                    <ListItemLink to="/leistungen">
                      <ListItemText primary="Leistungen" />
                    </ListItemLink>
                    <ListItemLink to="/branchen">
                      <ListItemText primary="Branchen" />
                    </ListItemLink>
                    <ListItemLink to="/kontakt">
                      <ListItemText primary="Kontakt" />
                    </ListItemLink>
                  </List>
                </Navul>
              </div>
            </Drawer>
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(Navigation)
