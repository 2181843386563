import React from 'react'
import Top from '../top'

const Header = () => (
  <div>
    <div
      style={{
        margin: '0 auto',
        maxWidth: 1280,
        padding: '1.45rem 1.0875rem 0',
      }}
    >
          <Top />

      
    </div>
  </div>
)

export default Header
