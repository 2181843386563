import React from 'react'
import Logoruberto from '../../images/steuerberatung-ruberto.png'

const Logo = () => (
  <div>
    <img src={Logoruberto} alt="" />
  </div>
)

export default Logo
