import React, { Component } from 'react'
import { Link } from 'gatsby'
import Logo from '../logo'
import Navigation from '../navigation'

class Top extends Component {
  render() {
    return (
      <div
        style={{
          margin: '0 auto',
          maxWidth: 1280,
          padding: '0px 1.0875rem',
          paddingTop: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Link to="/">
          <Logo />
        </Link>
        <Navigation />
      </div>
    )
  }
}

export default Top
